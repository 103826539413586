<template>
  <div class="content">
    <h4>Show</h4>
    <div class="background">
      <div v-if="!automation">
        <div class="row">
          <div class="col">
            <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
          </div>
        </div>
      </div>
      <div v-if="automation">
        <div class="row">
          <div class="col-6">
            <table class="table table-bordered">
              <thead>
                <th colspan="2">Automation</th>
              </thead>
              <tbody>
                <tr>
                  <td style="width: 200px;">Model</td>
                  <td>{{automation.model}}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{{automation.description}}</td>
                </tr>
                <tr>
                  <td>From</td>
                  <td>{{formatValue(data.from, 'longDate')}}</td>
                </tr>
                <tr>
                  <td>To</td>
                  <td>{{formatValue(data.to, 'longDate')}}</td>
                </tr>
                <tr>
                  <td>Interval</td>
                  <td>{{ data.interval == 1440 ? 'Every Day' : 'Every Hour' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <table class="table table-bordered">
              <thead>
                <th>Conditions</th>
              </thead>
              <tbody>
                <tr v-for="condition in data.configuration" :key="condition.id">
                  <td>{{condition}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="!data.results">
        <div class="row">
          <div class="col">
            <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
          </div>
        </div>
      </div>
      <div v-for="resultObject, key in data.results" :key="key">
        <div v-for="result, date in resultObject" :key="date" class="mt-4">
          <div class="row">
            <div class="col">
              <strong>{{date}}</strong>
            </div>
          </div>

          <div class="row" v-if="automation">
            <div class="col">
              <div v-if="result.length">
                <table class="table table-bordered">
                  <tbody>
                    <tr v-for="modelObj, k in result" :key="k">
                      <td style="white-space: nowrap;" width="1%">{{modelObj.id}}</td>
                      <td width="99%">{{modelObj.description}}</td>
                      <td style="white-space: nowrap;" width="1%" v-if="automation.model == 'User'">
                        <button class="vave-btn btn-sm btn-blue" :onclick="`window.open('/customers/`+modelObj.id+`', '_blank')`">
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                      <td style="white-space: nowrap;" width="1%" v-if="automation.model == 'Order'">
                        <button class="vave-btn btn-sm btn-blue" :onclick="`window.open('/orders/`+modelObj.id+`', '_blank')`">
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>No results</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import { formatValue } from "../formatters";

export default {
  data() {
    return {
      data: {},
      automation: null,
    };
  },

  components: {},
  mounted() {
    this.getResults();
  },
  methods: {
    formatValue,
    getResults() {
      axios
        .get("/api/admin/automations/" + this.$route.params.id)
        .then((response) => {
          if (response.data.data) {
            this.automation = response.data.data;
          }
        });

      axios
        .get(
          "/api/admin/automations/" +
            this.$route.params.id +
            "/simulations/" +
            this.$route.params.simulation
        )
        .then((response) => {
          if (response.data.data) {
            this.data = response.data.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
</style>